<template>
	<BreadCrumb />

	<el-button class="margin-10-0" size="mini" type="primary" @click="addDialog()">新增工单</el-button>
	<el-tabs v-model="activeName" :tab-position="tabPosition" @tab-click="handleClick" style="height: 400px">
		<el-tab-pane label="我创建的工单" name="1">
			<div class="page">
				<el-table v-loading="loading" :data="createTableData" fit stripe>
					<el-table-column :index="(ticketModule.page - 1) * pageSize + 1" label="序号" type="index" width="50" />
					<el-table-column label="工单编号" prop="issueTicketNum" width="140" show-overflow-tooltip />
					<el-table-column label="工单标题" prop="issueTicketTitle" width="100" />
					<el-table-column label="工单详细内容" prop="issueTicketContent" width="120" show-overflow-tooltip />
					<el-table-column label="工单状态" prop="issueTicketStatus" width="80" />
					<el-table-column label="工单优先级" prop="issueTicketPriority" width="80" />
					<el-table-column label="问题主键" prop="qualityIssue.issueId" />
					<el-table-column label="问题名称" prop="qualityIssue.issueDesc" width="100" show-overflow-tooltip />
					<el-table-column label="作业名称" prop="qualityIssue.work.workName" width="100" show-overflow-tooltip />
					<el-table-column label="任务主键" prop="qualityIssue.taskId"/>
					<el-table-column label="操作" fixed="right" width="140">
						<template #default="scope">
							<el-tooltip class="item" :class="{ disabled: scope.row.handleBy != null }" effect="light" content="修改工单" placement="top-end">
								<el-button type="primary" circle size="mini" @click="getTicketDataSourcer(scope.row)">
									<el-icon :size="15"><i-edit /></el-icon>
								</el-button>
							</el-tooltip>

							<el-tooltip class="item" :class="{ disabled: scope.row.handleBy != null }" content="删除工单" effect="light" placement="top-end">
								<el-button type="danger" circle size="mini" @click="delentSubmitStatus(scope.row)">
									<el-icon :size="15"><i-delete /></el-icon>
								</el-button>
							</el-tooltip>

							<el-tooltip :class="{ disabled: scope.row.submitStatus == '已提交' }" class="item" effect="light" content="提交工单" placement="top-end">
								<el-button type="primary" circle size="mini" @click="updateSubmitStatus(scope.row)">
									<el-icon :size="15"><i-finished /></el-icon>
								</el-button>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
					v-model:currentPage="ticketModule.page"
					:page-size="pageSize"
					:total="ticketModule.createTicketTotal"
					background
					layout="total, prev, pager, next, jumper"
					@current-change="handleCurrentChange"
				/>
			</div>
		</el-tab-pane>

		<el-tab-pane label="我提交的工单" name="2">
			<div class="page">
				<el-table v-loading="loading" :data="submitTableData" fit stripe>
					<el-table-column :index="(ticketModule.submitPage - 1) * pageSize + 1" label="序号" type="index" width="50" />
					<el-table-column label="工单编号" prop="issueTicketNum" width="140" show-overflow-tooltip />
					<el-table-column label="工单标题" prop="issueTicketTitle" width="100" />
					<el-table-column label="工单详细内容" prop="issueTicketContent" width="120" show-overflow-tooltip />
					<el-table-column label="工单状态" prop="issueTicketStatus"/>
					<el-table-column label="工单优先级" prop="issueTicketPriority"  />
					<el-table-column label="问题主键" prop="qualityIssue.issueId"  />
					<el-table-column label="问题名称" prop="qualityIssue.issueDesc" width="100" show-overflow-tooltip />
					<el-table-column label="作业名称" prop="qualityIssue.work.workName" width="100" show-overflow-tooltip />
					<el-table-column label="任务主键" prop="qualityIssue.taskId" />
					<el-table-column label="操作" fixed="right" >
						<template #default="scope">
							<el-tooltip class="item" :class="{ disabled: scope.row.handleBy != null }" content="删除工单" effect="light" placement="top-end">
								<el-button type="danger" circle size="mini" @click="delentSubmitStatus(scope.row)">
									<el-icon :size="15"><i-delete /></el-icon>
								</el-button>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
					v-model:currentPage="ticketModule.submitPage"
					:page-size="pageSize"
					:total="ticketModule.submitTotal"
					background
					layout="total, prev, pager, next, jumper"
					@current-change="submiCurrentChange"
				/>
			</div>
		</el-tab-pane>
		<!-- <el-tab-pane label="我未解决的工单" name="3">Role</el-tab-pane> -->

		<el-tab-pane label="指派给我的工单" name="3">
			<div class="page">
				<el-table v-loading="loading" :data="appointTableData" fit stripe>
					<el-table-column :index="(ticketModule.appointpage - 1) * pageSize + 1" label="序号" type="index" width="50" />
					<el-table-column label="工单编号" prop="issueTicketNum" width="140" show-overflow-tooltip />
					<el-table-column label="工单标题" prop="issueTicketTitle" width="100" />
					<el-table-column label="工单详细内容" prop="issueTicketContent" width="120" show-overflow-tooltip />
					<el-table-column label="工单优先级" prop="issueTicketPriority"/>
					<el-table-column label="问题主键" prop="qualityIssue.issueId"  />
					<el-table-column label="问题名称" prop="qualityIssue.issueDesc" width="100" show-overflow-tooltip />
					<el-table-column label="作业名称" prop="qualityIssue.work.workName" width="100" show-overflow-tooltip />
					<el-table-column label="任务主键" prop="qualityIssue.taskId" />
					<el-table-column label="操作" fixed="right" width="100">
						<template #default="scope">
							<el-tooltip class="item" :class="{ disabled: scope.row.issueTicketStatus != '1' }" content="解决工单" effect="light" placement="top-end">
								<el-button type="primary" circle size="mini" @click="resolveAppointStatus(scope.row)">
									<el-icon :size="15"><i-check /></el-icon>
								</el-button>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
					v-model:currentPage="ticketModule.appointpage"
					:page-size="pageSize"
					:total="ticketModule.appointTotal"
					background
					layout="total, prev, pager, next, jumper"
					@current-change="appointCurrentChange"
				/>
			</div>
		</el-tab-pane>

		<el-tab-pane label="我已解决的工单" name="4">
			<div class="page">
				<el-table v-loading="loading" :data="resolvedTableData" fit stripe>
					<el-table-column :index="(ticketModule.resolvedpage - 1) * pageSize + 1" label="序号" type="index" width="50" />
					<el-table-column label="工单编号" prop="issueTicketNum" width="140" show-overflow-tooltip />
					<el-table-column label="工单标题" prop="issueTicketTitle" width="140" />
					<el-table-column label="工单详细内容" prop="issueTicketContent" width="120" show-overflow-tooltip />
					<el-table-column label="工单优先级" prop="issueTicketPriority"  />
					<el-table-column label="工单处理人" prop="handleBy" width="70" />
					<el-table-column label="处理时间" prop="handleTime" show-overflow-tooltip />
					<el-table-column label="工单状态" prop="issueTicketStatus" />
					<el-table-column label="问题主键" prop="qualityIssue.issueId"/>
					<el-table-column label="问题名称" prop="qualityIssue.issueDesc" width="100" show-overflow-tooltip />
					<el-table-column label="作业名称" prop="qualityIssue.work.workName" width="100" show-overflow-tooltip />
					<el-table-column label="任务主键" prop="qualityIssue.taskId" />
				</el-table>
				<el-pagination
					v-model:currentPage="ticketModule.resolvedpage"
					:page-size="pageSize"
					:total="ticketModule.resolvedTotal"
					background
					layout="total, prev, pager, next, jumper"
					@current-change="resolvedCurrentChange"
				/>
			</div>
		</el-tab-pane>
	</el-tabs>

	<el-dialog v-model="dataSourceAdd" :title="dataSourceTitle">
		<el-form ref="form" :model="form" :rules="TicketRules" >
			<el-form-item label="工单标题:" prop="issueTicketTitle"><el-input v-model="form.issueTicketTitle" placeholder="请输入工单标题" /></el-form-item>
			<el-form-item label="工单描述:" prop="issueTicketContent"><el-input v-model="form.issueTicketContent" placeholder="工单详细内容" /></el-form-item>
			<el-form-item label="工单级别:" prop="issueTicketPriority">
				<el-radio-group v-model="form.issueTicketPriority">
					<el-radio :label="0">低</el-radio>
					<el-radio :label="1">中</el-radio>
					<el-radio :label="2">高</el-radio>
					<el-radio :label="3">紧急</el-radio>
					<el-radio :label="4">非常紧急</el-radio>
				</el-radio-group>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="dataSourceAdd = false">取消</el-button>
				<el-button v-if="dataSourceUpdate" type="primary" @click="UpdateTicket('form')">修 改</el-button>
			</span>
		</template>
	</el-dialog>

	<el-dialog v-model="dialog.isShow" :title="dialog.title">
		<el-form ref="myProject" :model="myProject" :rules="TicketRules">
			<el-form-item label="工单标题" prop="issueTicketTitle"><el-input v-model="myProject.issueTicketTitle" autocomplete="off" placeholder="请输入工单标题" /></el-form-item>
			<el-form-item label="工单描述" prop="issueTicketContent">
				<el-input v-model="myProject.issueTicketContent" placeholder="请输入工单详细内容" type="textarea" />
			</el-form-item>
			<el-form-item label="工单级别" prop="issueTicketPriority">
				<el-radio-group v-model="myProject.issueTicketPriority">
					<el-radio :label="0">低</el-radio>
					<el-radio :label="1">中</el-radio>
					<el-radio :label="2">高</el-radio>
					<el-radio :label="3">紧急</el-radio>
					<el-radio :label="4">非常紧急</el-radio>
				</el-radio-group>
			</el-form-item>
			
			<el-form-item label="工单问题:" prop="issueTitle" >
				<el-select v-model="myProject.issueTitle" placeholder="工单问题" style="width: 100%">
					<el-option v-for="item in databaseTotal" :key="item.value" :label="item.label" :value="item.id" />
				</el-select>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button type="primary" @click="addTicket('myProject')">确 定</el-button>
        <el-button @click="dialog.isShow = false">取 消</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script>
/* eslint-disable */

import BreadCrumb from '/src/components/BreadCrumb';
import { mapGetters } from 'vuex';
export default {
	name: 'my-ticket',
	components: {
		BreadCrumb
	},
	data() {
		const checkCompanyName = (rule, value, callback) => {
			if (value.trim() == '') {
				callback(new Error('请输入规范的数据'));
				return false;
			}
			return true;
		};
		return {
			level: 3,
			tabPosition: 'left',
			loading: true,
			createTableData: '', //创建工单数据
			submitTableData: '', //创建工单数据
			id: 0, //工单编号
			dataSourceAdd: false, //修改工单弹窗
			dataSourceUpdate: false, //修改工单弹窗确认按钮
			dataSourceTitle: '', //修改工单弹窗标题
			resolvedTableData: '', //已解决的工单内容
			appointTableData: '', //指派给我的订单
			databaseTotal: [], //问题的数据
			form: {
				//单条工单数据
				issueTicketTitle: '',
				issueTicketContent: '',
				issueTicketPriority: 0
			},
			ticketModule: {
				//工单模块
				page: 1,
				createTicketTotal: 0,
				submitPage: 1,
				submitTotal: 0,
				resolvedpage: 1,
				resolvedTotal: 0,
				appointpage: 1,
				appointTotal: 0
			},
			dialog: {
				//新增工单模块
				isShow: false,
				title: '新增工单'
			},
			myProject: {
				//新增工单绑定数据
				issueTicketTitle: '',
				issueTicketContent: '',
				issueTicketPriority: 7,
				issueTitle: ""
			},
			TicketRules: {
				//新增工单验证
				issueTicketTitle: [
					{
						required: true,
						message: '工单标题不可为空',
						trigger: 'blur'
					},
					{ required: true, validator: checkCompanyName, trigger: 'change' }
				],
				issueTicketContent: [
					{
						required: true,
						message: '工单详细内容不可为空',
						trigger: 'blur'
					},
					{ required: true, validator: checkCompanyName, trigger: 'change' }
				],
				issueTicketPriority: [
					{
						required: true,
						message: '请选择工单优先级',
						trigger: 'blur'
					}
				],
				issueTitle: [
					{
						required: true,
						message: '工单问题不能为空',
						trigger: 'blur'
					}
				]
			},
			activeName: '1'
		};
	},
	computed: {
		...mapGetters(['pageSize', 'user'])
	},
	mounted: function() {
		const that = this;
		that.ticketList();
	},
	methods: {
		ticketList() {
			const that = this;
			that.$axios
				.POST('/api/v1/core/issueTicket/findAllCreateTicket', {
					page: that.ticketModule.page,
					size: that.pageSize
				})
				.then(res => {
					if (res.code == 200) {
						that.loading = false;
						that.createTableData = res.data.list;
						for (let item of that.createTableData) {
							if (item.issueTicketStatus == 0) {
								item.issueTicketStatus = '尚未受理';
							} else if (item.issueTicketStatus == 1) {
								item.issueTicketStatus = '受理中';
							} else if (item.issueTicketStatus == 2) {
								item.issueTicketStatus = '已解决';
							} else if (item.issueTicketStatus == 3) {
								item.issueTicketStatus = '已关闭';
							}

							if (item.issueTicketPriority == 0) {
								item.issueTicketPriority = '低';
							} else if (item.issueTicketPriority == 1) {
								item.issueTicketPriority = '中 ';
							} else if (item.issueTicketPriority == 2) {
								item.issueTicketPriority = '高';
							} else if (item.issueTicketPriority == 3) {
								item.issueTicketPriority = '紧急';
							} else if (item.issueTicketPriority == 4) {
								item.issueTicketPriority = '非常紧急';
							}

							if (item.submitStatus == 0) {
								item.submitStatus = '未提交';
							} else if (item.submitStatus == 1) {
								item.submitStatus = '已提交';
							}
 						}
						that.ticketModule.createTicketTotal = res.data.total;
					} else {
						this.$message({ showClose: true, message: '查询工单失败', type: 'error' });
					}
			
				});
		},

		updateSubmitStatus(row) {
			//更新工单提交状态
			var that = this;
			that
				.$confirm('确定提交工单编号为' + row.issueTicketNum + '的工单', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				})
				.then(() => {
					that.$axios.PUT('/api/v1/core/issueTicket/updateTicketSubmitStatus/' + row.id).then(res => {
						if (res.code == 200) {
							that.$message({
								type: 'success',
								message: '提交成功!'
							});
							that.ticketList(that.ticketModule.page, that.pageSize);
						} else {
							that.$message({ showClose: true, message: '修改失败', type: 'error' });
						}
					});
				})
				.catch(() => {
					that.$message({
						type: 'info',
						message: '取消提交'
					});
				});
		},

		//删除未被指派工单
		delentSubmitStatus(row) {
			var that = this;
			that
				.$confirm('确定删除工单编号为' + row.issueTicketNum + '的工单', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				})
				.then(() => {
					that.$axios.DELETE('/api/v1/core/issueTicket/deleteTicket/' + row.id).then(res => {
						if (res.code == 200) {
							that.$message({
								type: 'success',
								message: '删除成功!'
							});
							that.ticketList(that.ticketModule.page, that.pageSize);
						} else {
							that.$message({ showClose: true, message: '删除失败', type: 'error' });
						}
					});
				})
				.catch(() => {
					that.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		},
		//解决指派的工单
		resolveAppointStatus(row) {
			var that = this;
			that
				.$confirm('确定将工单编号为' + row.issueTicketNum + '的工单更改为已解决吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				})
				.then(() => {
					that.$axios.DELETE('/api/v1/core/issueTicket/resolveAppointTicket/' + row.id).then(res => {
						if (res.code == 200) {
							that.$message({
								type: 'success',
								message: '更改成功!'
							});
							that.appointTableDataFun(that.ticketModule.appointpage, that.pageSize);
						} else if (res.code == 400) {
							this.$message({
								type: 'info',
								message: res.message
							});
						} else {
							that.$message({ showClose: true, message: '更改失败', type: 'error' });
						}
					});
				})
				.catch(() => {
					that.$message({
						type: 'info',
						message: '已取消'
					});
				});
		},

		//已提交的工单数据
		submitTableDataFun() {
			const that = this;
			that.$axios
				.POST('/api/v1/core/issueTicket/findAllSubmitTicket', {
					page: that.ticketModule.submitPage,
					size: that.pageSize
				})
				.then(res => {
					if (res.code == 200) {
						that.loading = false;
						that.submitTableData = res.data.list;
						that.ticketModule.submitTotal = res.data.total;
						for (let item of that.submitTableData) {
							if (item.issueTicketStatus == 0) {
								item.issueTicketStatus = '尚未受理';
							} else if (item.issueTicketStatus == 1) {
								item.issueTicketStatus = '受理中';
							} else if (item.issueTicketStatus == 2) {
								item.issueTicketStatus = '已解决';
							} else if (item.issueTicketStatus == 3) {
								item.issueTicketStatus = '已关闭';
							}

							if (item.issueTicketPriority == 0) {
								item.issueTicketPriority = '低';
							} else if (item.issueTicketPriority == 1) {
								item.issueTicketPriority = '中 ';
							} else if (item.issueTicketPriority == 2) {
								item.issueTicketPriority = '高';
							} else if (item.issueTicketPriority == 3) {
								item.issueTicketPriority = '紧急';
							} else if (item.issueTicketPriority == 4) {
								item.issueTicketPriority = '非常紧急';
							}

							if (item.submitStatus == 0) {
								item.submitStatus = '未提交';
							} else if (item.submitStatus == 1) {
								item.submitStatus = '已提交';
							}
						}
					} else {
						this.$message({ showClose: true, message: '查询工单失败', type: 'error' });
					}
				});
		},
		//已解决的工单
		resolvedTableDataFun() {
			const that = this;
			that.$axios
				.POST('/api/v1/core/issueTicket/findAllResolvedTicket', {
					page: that.ticketModule.resolvedPage,
					size: that.pageSize
				})
				.then(res => {
					if (res.code == 200) {
						that.loading = false;
						that.resolvedTableData = res.data.list;
						that.ticketModule.resolvedTotal = res.data.total;
						for (let item of that.resolvedTableData) {
							if (item.issueTicketStatus == 0) {
								item.issueTicketStatus = '尚未受理';
							} else if (item.issueTicketStatus == 1) {
								item.issueTicketStatus = '受理中';
							} else if (item.issueTicketStatus == 2) {
								item.issueTicketStatus = '已解决';
							} else if (item.issueTicketStatus == 3) {
								item.issueTicketStatus = '已关闭';
							}

							if (item.issueTicketPriority == 0) {
								item.issueTicketPriority = '低';
							} else if (item.issueTicketPriority == 1) {
								item.issueTicketPriority = '中 ';
							} else if (item.issueTicketPriority == 2) {
								item.issueTicketPriority = '高';
							} else if (item.issueTicketPriority == 3) {
								item.issueTicketPriority = '紧急';
							} else if (item.issueTicketPriority == 4) {
								item.issueTicketPriority = '非常紧急';
							}
							if (item.submitStatus == 0) {
								item.submitStatus = '未提交';
							} else if (item.submitStatus == 1) {
								item.submitStatus = '已提交';
							}
						}
					} else {
						this.$message({ showClose: true, message: '查询工单失败', type: 'error' });
					}
				});
		},
		//指派给我的工单
		appointTableDataFun() {
			const that = this;
			that.$axios
				.POST('/api/v1/core/issueTicket/findAllAppointTicket', {
					page: that.ticketModule.appointPage,
					size: that.pageSize
				})
				.then(res => {
					if (res.code == 200) {
						that.loading = false;
						that.appointTableData = res.data.list;
						that.ticketModule.appointTotal = res.data.total;
						for (let item of that.appointTableData) {
							if (item.issueTicketPriority == 0) {
								item.issueTicketPriority = '低';
							} else if (item.issueTicketPriority == 1) {
								item.issueTicketPriority = '中 ';
							} else if (item.issueTicketPriority == 2) {
								item.issueTicketPriority = '高';
							} else if (item.issueTicketPriority == 3) {
								item.issueTicketPriority = '紧急';
							} else if (item.issueTicketPriority == 4) {
								item.issueTicketPriority = '非常紧急';
							}
						}
					} else {
						this.$message({ showClose: true, message: '查询工单失败', type: 'error' });
					}
				});
		},
		//修改工单弹窗
		getTicketDataSourcer(row) {
			const that = this;
			that.$axios.GET('/api/v1/core/issueTicket/findTicketByNum/' + row.id).then(res => {
				if (res.code == 200) {
					that.id = res.data.id;
					that.form.issueTicketTitle = res.data.issueTicketTitle;
					that.form.issueTicketContent = res.data.issueTicketContent;
					that.form.issueTicketPriority = res.data.issueTicketPriority;
				} else {
					that.$message({ showClose: true, message: '消息获取失败', type: 'error' });
				}
			});
			that.dataSourceAdd = true;
			that.dataSourceUpdate = true;
			that.dataSourceTitle = '修改工单';
		},

		//更新工单信息==================================================================================================================
		UpdateTicket(form) {
			const that = this;
			let datas = {
				id: that.id,
				issueTicketTitle: that.form.issueTicketTitle,
				issueTicketContent: that.form.issueTicketContent,
				issueTicketPriority: that.form.issueTicketPriority
			};
			that.$refs[form].validate(valid => {
				if (valid) {
				that.$axios.PUT('/api/v1/core/issueTicket/updateTicketByNum', datas).then(res => {
					if (res.code == 200) {
						that.dataSourceAdd = false;
						that.$message({
							type: 'success',
							message: '修改成功!'
						});
						that.ticketList(that.ticketModule.page, that.pageSize);
					} else if (res.code == 400) {
						that.$message({
							type: 'info',
							message: res.message
						});
					} else {
						that.$message({ showClose: true, message: '修改失败', type: 'error' });
					}
				});
				}
			})
			
		},
		//新增工单弹窗
		addDialog() {
			this.$axios.GET('/api/v1/core/issueTicket/getIssueDesc').then(res => {
				if (res.code == 200) {
					this.databaseTotal = [];
					$.each(res.data, (key, value) => {
						const dropDownFrame = { value: '', label: '' ,id: ''};
						dropDownFrame.value = key;
						dropDownFrame.label = value.issue_desc;
						dropDownFrame.id=value.issue_id;
						this.databaseTotal.push(dropDownFrame);
					});
				} else {
					this.$message({ showClose: true, message: '打开新增失败', type: 'error' });
				}
			});
			this.dialog.isShow = true;
			Object.keys(this.myProject).forEach(key => (this.myProject[key] = ''));
		},
		//新增工单
		addTicket(myProject) {
			var that = this;
			let { issueTicketTitle, issueTicketContent, issueTicketPriority,issueTitle} = that.myProject;
			that.$refs[myProject].validate(valid => {
				if (valid) {
					let datas = {
						issueTicketTitle: issueTicketTitle,
						issueTicketContent: issueTicketContent,
						issueTicketPriority: issueTicketPriority,
						issueId: issueTitle
					};
					this.$axios.PUT('/api/v1/core/issueTicket/insertTicket', datas).then(function(result) {
						that.dialog.isShow = false;
						if (result.code == 200) {
							that.$message({
								showClose: true,
								message: '工单新增成功',
								type: 'success'
							});
							that.ticketList(that.ticketModule.page, that.pageSize);
						} else if (result.code == 400) {
							that.dialog.isShow = true;
						} else {
							this.$message({ showClose: true, message: '工单新增失败', type: 'error' });
						}
					});
				} else {
					return false;
				}
			});
		},

		handleCurrentChange(currentPage) {
			const that = this;
			console.log(currentPage);
			that.ticketModule.page = currentPage;
			that.ticketList();
		},
		submiCurrentChange(currentPage) {
			const that = this;
			console.log(currentPage);
			that.ticketModule.submitPage = currentPage;
			that.submitTableDataFun();
		},
		resolvedCurrentChange(currentPage) {
			const that = this;
			console.log(currentPage);
			that.ticketModule.resolvedPage = currentPage;
			that.resolvedTableDataFun();
		},
		appointCurrentChange(currentPage) {
			const that = this;
			console.log(currentPage);
			that.ticketModule.appointPage = currentPage;
			that.appointTableDataFun();
		},
		handleClick(tab, event) {
			console.log(event.target.id);
			if (event.target.id == 'tab-1') {
				this.ticketList();
			} else if (event.target.id == 'tab-2') {
				this.submitTableDataFun();
			} else if (event.target.id == 'tab-3') {
				this.appointTableDataFun();
			} else if (event.target.id == 'tab-4') {
				this.resolvedTableDataFun();
			}
		}
	}
};
</script>

<style scoped></style>
